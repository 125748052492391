import _findIndex from 'lodash/findIndex';
import type { WCmsWeddingPartyMemberView } from '@zola/svc-web-api-ts-client';

export const WeddingPartyMemberRoles = [
  {
    title: 'Bride',
    value: 'BRIDE',
  },
  {
    title: 'Groom',
    value: 'GROOM',
  },
  {
    title: 'Best Man',
    value: 'BEST_MAN',
  },
  {
    title: 'Best Woman',
    value: 'BEST_WOMAN',
  },
  {
    title: 'Maid of Honor',
    value: 'MAID_OF_HONOR',
  },
  {
    title: 'Matron of Honor',
    value: 'MATRON_OF_HONOR',
  },
  {
    title: 'Man of Honor',
    value: 'MAN_OF_HONOR',
  },
  {
    title: 'Groomsman',
    value: 'GROOMSMAN',
  },
  {
    title: 'Groomswoman',
    value: 'GROOMSWOMAN',
  },
  {
    title: 'Bridesmaid',
    value: 'BRIDESMAID',
  },
  {
    title: 'Bridesman',
    value: 'BRIDESMAN',
  },
  {
    title: 'Father of the Bride',
    value: 'FATHER_OF_THE_BRIDE',
  },
  {
    title: 'Mother of the Bride',
    value: 'MOTHER_OF_THE_BRIDE',
  },
  {
    title: 'Father of the Groom',
    value: 'FATHER_OF_THE_GROOM',
  },
  {
    title: 'Mother of the Groom',
    value: 'MOTHER_OF_THE_GROOM',
  },
  {
    title: 'Honor Attendant',
    value: 'ATTENDANT',
  },
  {
    title: 'Flower Girl',
    value: 'FLOWER_GIRL',
  },
  {
    title: 'Flower Child',
    value: 'FLOWER_CHILD',
  },
  {
    title: 'Candle Lighter',
    value: 'CANDLE_LIGHTER',
  },
  {
    title: 'Officiant',
    value: 'OFFICIANT',
  },
  {
    title: 'Ring Bearer',
    value: 'RING_BEARER',
  },
  {
    title: 'Usher',
    value: 'USHER',
  },
  {
    title: 'Wedding Planner',
    value: 'WEDDING_PLANNER',
  },
  {
    title: 'Customize Your Own Role',
    value: 'CUSTOM',
  },
];

export const dropdownV3WeddingPartyRoles = WeddingPartyMemberRoles.map(({ title, value }) => ({
  label: title,
  value,
}));

export const getWeddingPartyMemberRole = (role: WCmsWeddingPartyMemberView['role']) => {
  const idx = _findIndex(WeddingPartyMemberRoles, (elem) => elem.value === role);
  return idx >= 0 ? WeddingPartyMemberRoles[idx].title : '';
};
