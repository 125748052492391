import styled from '@emotion/styled';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const Person = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow-wrap: anywhere;
`;

export const PersonWrapper = styled.div`
  position: relative;
  height: calc(100% - 60px);
  margin-top: 60px;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.MEDIA_QUERY.DESKTOP} {
    margin-top: ${({ theme }) => theme.SPACING.LG};
    height: calc(100% - ${SPACING.LG});
  }
`;

type ContainerProps = { orientation: string };

export const Container = styled.div<ContainerProps>`
  margin-top: 60px;
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.MEDIA_QUERY.DESKTOP} {
    margin-top: ${({ theme }) => theme.SPACING.XL};
  }

  ${({ orientation, theme }) =>
    orientation === 'HORIZONTAL' &&
    `

    ${theme.MEDIA_QUERY.EXCLUDE_MOBILE} {
      display: grid;
      grid-template-columns: 1fr 1fr;

      ${Person} {
        padding: 0 ${theme.SPACING.LG};
      }
      
      > div { 
        :last-of-type:nth-of-type(2n + 1) { // if there is a dangler
            border-right: none;
            grid-column-end: 3;
            grid-column-start: 1;   
        }
      }
    }
  `}
`;

export const StyledHr = styled.hr`
  height: 100%;
  display: block;
  border-right: 1px solid;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;

  ${MEDIA_QUERY_V2.MOBILE} {
    display: none;
  }
`;

export const Name = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${({ theme }) => theme.SPACING.SM};
`;

export const Role = styled.p`
  margin-bottom: ${({ theme }) => theme.SPACING.SM};
`;

export const Blurb = styled.p`
  margin-bottom: 0;
  white-space: pre-wrap;
`;

export const Headshot = styled.img`
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto ${({ theme }) => theme.SPACING.MD};
  width: 150px;
`;

export const EmptyStateMessage = styled.p`
  text-align: center;
`;
